@import './variables.scss';



*,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

input,
button,
textarea {
  font-family: inherit;
}

a {
  color: darken(#0572d1, 15%);
  font-weight: 500;
}

.center {
  text-align: center;
}

p {
  font-weight: 500;
  margin-bottom: 1rem;
  line-height: 1.5;
}

.wrapper {
  width: 90%;
  margin: 0 auto;
  max-width: 100rem;
}

.top-head-wrapper{
  width: 90%;
  margin: 0 auto;
  max-width: 100rem;
}

html,
body,
#root {
  height: 100%;
}

body,html{
  overflow-x: hidden;
}

.dev-panel {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  p {
    margin-top: 1rem;
    color: $secondaryColor;
    max-width: 400px;
    font-weight: 600;
  }
}

label,
p {
  color: #222;
}

.loading-main-container {
  position: relative;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-main-container i {
  color: #0572d1;
  font-size: 2rem;
  mix-blend-mode: difference;
}

.loading-main-container-filler {
  height: 100%;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.2s linear;
}

.error-message-title {
  color: #fff;
  margin-bottom: 1rem;
}

.error-message-link {
  display: inline-block;
  text-decoration: none;
  margin-top: 2rem;

  i {
    margin-right: 0.5rem;
  }
}

.error-message {
  color: rgb(204, 112, 112);

  i {
    margin-right: 0.5rem;
  }
}

.loading-container {
  position: relative;
}

.loading-filler {
  width: 100%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-filler-fill {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.loading-filler-static {
  padding: 2rem 0;
}

.loading-filler span {
  display: inline-block;
  margin-left: 0.5rem;
}



#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.full-content {
  background-color: #f1f1f1;
  flex: 1 0 auto;
}

main {
  background-color: #f1f1f1;
  padding: 4rem 0;
}

main h1 {
  margin-bottom: 3rem;
  color: #222;
  text-align: center;

}

.main-about{
  padding-top: 0;
  padding-bottom: 0;
}

main h1.nomargin {
  margin-bottom: 1rem;
}

.button {
  background: #0572d1;
  color: #fff;
  font-weight: 500;
  padding: 0.625rem 2rem;
  display: inline-block;
  border: none;
  cursor: pointer;
  transition: all 0.22s;
}

.button:hover {
  background: #222;
}

.button.disabled {
  opacity: 0.4;
  pointer-events: none;
}

.breadcrumb {
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  font-size: 1rem;
  justify-content: center;
}

.breadcrumb a {
  font-weight: 400;
  color: #666;
  text-decoration: none;
}



.breadcrumb a.on {
  color: #0572d1;
  font-weight: bold;
}

.breadcrumb i {
  color: #666;
  margin: 0 1rem;
  display: inline-block;
}

/*PUPUP*/

.popup {
  height: 100%;
  width: 100%;
  background-image: url('./assets/images/background.jpg');
  background-size: cover;
  position: fixed;
  z-index: 4000000;
  background-position: center;
  transform: translate3d(0, -100%, 0);
  transition: all 1s;
}

.popup-visible {
  transform: translate3d(0, 0, 0);
}

/* HEADER */



/* ::-webkit-search-decoration {
  display: none;
} */


.header-top-container {
  border-bottom: 1px solid rgba(255, 234, 234, 0.329);
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container-suport-certif {
  display: flex;
  justify-content: center;
  align-items: center;

}

.certificate-img {
  margin-right: 20px;

  img {
    width: 60px;
   
    
  }
}

.nice-container {
 margin-top: 40px;
 margin-bottom: 40px;

 h1{
  margin-top: 30px;
  margin-bottom: 30px;
 }
}



.details-nice-container {
width: 80%;

li{
  list-style: none;
}
}

.nice-img {
  width: 100%;
  height: 350px;
}

.certif {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 5px;
}

.nice-img-responsive {
  display: none;
  width: 100%;
}

.tecnic-support {
  color: white;
  border: 1px solid white;
  padding: 3px 15px;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  

}

.wp-top {
  color: white;
  text-decoration: none;
  margin-left: 20px;
}


.input-search form {
  display: flex;
  align-items: center;
  width: 100%;

}

.middle-header {
  width: 360px;

  padding: 10px 0;
  text-align: start;
  border: none;
  
}


.input-search button{
  background-color: white;
  cursor: pointer;
  border: none;
  padding: 2.2px ;
}

.logo-search{
  width: 30px;
}

.input-search .middle-header:focus {
  outline: none;
}

header .wrapper > nav {
  position: absolute;
  top: calc(100% + 1rem);
  width: 100%;
  right: 0;
  left: 5%;
  max-width: 20rem;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  padding: 0.5rem 1rem;
  border-radius: 5px;
  opacity: 0;
  visibility: hidden; 
  transform: translate3d(0, -2rem, 0);
  transition: all 0.2s;

}

header .wrapper > nav.active {
  transform: translate3d(0, 0, 0);
  opacity: 1;
  visibility: visible;
  z-index: 60000;
}

header nav > ul > li > ul {
  display: none;
  position: static;
  transform: translate3d(0, 0, 0);
  z-index: 60000;
}

header nav > ul > li > a {
  padding: 0;
  color: #222;
  z-index: 60000;
}

header nav .user-actions-container a {
  color: #222;
}

header nav > ul > li > a i {
  opacity: 0;
  position: absolute;
  pointer-events: none;
  z-index: 60000;
}

header nav > ul > li {
  display: block;
  padding: 0.5rem 0;
  text-align: center;
}

.top-header {
  padding: 0.5rem 0;
  background: #fff;
  color: #fff;
  text-align: center;
  position: relative;
}



.top-header i {
  position: absolute;
  right: 1rem;
  bottom: 14px;
  cursor: pointer;
  color: #000;
}

.top-header p {
  color: black;
  margin-bottom: 0;
}

header .wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  
}

.header-right{
  display: flex;
  align-items: center;
}

header {
  background: #0572d1;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  z-index: 900000;
  position: relative;
}

header .logo img {
  height: 5rem;
  margin: 15px 30px;
}

header nav > ul > li {
  display: block;
  position: relative;
  font-weight: 500;
}

header nav .user-actions-container {
  display: none;
}

header nav > ul > li > a {
  display: block;
  text-transform: uppercase;
  text-decoration: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 600;
  transition: all 0.22s;
  color: #000;
}

header nav > ul > li > a.active {
  color: #222;
}

header nav > ul > li > ul > li > ul > li > a:hover,
header nav > ul > li > ul > li:hover > a,
header nav > ul > li.active > a,
header nav > ul > li:hover > a {
  color: #222;
}

header nav > ul > li > ul {
  position: absolute;
  top: calc(100% + 2rem);
  min-width: 20rem;
  background: #fff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.4);
  padding: 1rem 0;
  transition: all 0.22s;
  transform: translate3d(0, -1.5rem, 0);
  opacity: 0;
  visibility: hidden;
  z-index: 2000;
}

header nav > ul > li > ul > li:hover > ul,
header nav > ul > li:hover > ul {
  opacity: 1;
  visibility: visible;
  transform: translate3d(0, 0, 0);
  transition: all 0.22s;
}

header nav > ul > li > ul > li {
  display: block;
  list-style: none;
  position: relative;
  padding: 0 1rem;
}

header nav > ul > li > ul > li i {
  font-size: 0.875rem;
}

header nav > ul > li > ul > li > a {
  color: $secondaryColor;
  display: block;
  font-weight: 600;
  text-decoration: none;
  line-height: 1.5;
  transition: all 0.22s;
}

header nav > ul > li > ul > li > ul {
  position: absolute;
  background: #fff;
  text-decoration: none;
  top: 0;
  left: calc(100% + 0.5rem);
  min-width: 12rem;
  padding: 1rem;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  opacity: 0;
  transform: translate3d(1rem, 0, 0);
  visibility: hidden;
}

header nav > ul > li > ul > li > ul:before {
  width: 0.5rem;
  height: 100%;
  background: transparent;
  position: absolute;
  content: '';
  display: block;
  top: 0;
  left: -0.5rem;
}

header nav > ul > li > ul > li > ul > li {
  list-style: none;
}

header nav > ul > li > ul > li > ul > li > a {
  text-decoration: none;
  padding: 0.25rem 0;
  font-weight: 600;
  display: block;
  color: $secondaryColor;
}

.background-video{
  width: 100%;

}

.video-responsive{
  display: none;
}

.mask {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("../src/assets/images/mask.png");
  opacity: 0.5; 

}

.principal-container {
  position: relative;
}

.principal-container video {
  width: 100%;
  position: relative;
}

.video-responsive {
  display: none;
}

.text {
  position: absolute;
  z-index: 2;
  text-align: start;
  top: 22vw;
  left: 8vw;
  width: 60%;
  color: #fff;
}

.cursor-text {
  display: none;
}

.texts-spans {
  text-transform: uppercase;
  font-size: 3rem;
  list-style: none;
  top: 0;
  position: relative;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
}


/* new */

.cancel-purchase-button {
  color: #d4a244;
  background: none;
  border: none;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    color: darken(#d4a244, 15%);
  }

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}

.gold-button {
  background-color: #d6b556;
  background: url(./assets/images/gold.png) repeat;
  border: none;
  cursor: pointer;
  color: #0f0f1f;
  border-radius: 4px;
  display: inline-block;
  font-weight: 600;
  padding: 10px 20px;
  text-decoration: none;
  transition: all 0.2s;
  box-shadow: 0 2px 4px 0 rgba(241, 196, 111, 0.2);
}

.gold-button:hover {
  transform: translate3d(0, 3px, 0);
}

.gold-button.disabled {
  pointer-events: none;
  opacity: 0.4;
}

.hero {
  height: 40rem;
  background-image: url(./assets/images/home/hero.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.hero h1 {
  color: #fff;
  font-size: 4rem;
}

.hero a {
  margin-top: 1.5rem;
}

.hero-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hero-pouch img {
  height: 30rem;
  display: inline-block;
  z-index: 2;
  position: relative;
  margin-right: 10rem;
}

.hero-pouch {
  position: relative;
}

.hero-pouch img.shadow {
  position: absolute;
  top: 1rem;
  filter: blur(7px);
  z-index: 1;
  left: 0;
}

.home-section {
  padding: 1.5rem 0;
}

.page-title,
.home-section h2 {
  color: #222;
  font-weight: 600;
  font-size: 2rem;
  position: relative;
  display: inline-block;
  margin-bottom: 2rem;
  text-transform: uppercase;
}

.home-section h2 span {
  position: relative;
  z-index: 1;
}

.showcase {
  background-color: #f1f1f1;

  h2 {
    display: block;
    width: 100%;
    text-align: center;
    color: $accentColor;
    font-size: 2.5rem;
    margin-bottom: 3rem;
  }
}

.store-loading,
.showcase-loading {
  display: flex;
  padding: 8rem 0;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: $accentColor;
}

.store-loading span,
.showcase-loading span {
  display: inline-block;
  margin-left: 16px;
}

.showcase-more {
  margin-top: 1.5rem;
}

.store-grid,
.product-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 20px;
}

.category,
.product {
  background: #fff;
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  transition: all 0.22s;
  background: #fff;
  box-shadow: 0 3px 6px 0 rgba(15, 15, 31, 0.1);
  transition: all 0.2s;
  border-radius: 3px;
  overflow: hidden;
}

.category:hover,
.product:hover {
  transform: translate3d(0, -0.5rem, 0);
  box-shadow: 0 8px 16px 0 rgba(15, 15, 31, 0.2);
}

.top-part img {
  width: 100%;
  display: block;
}

.bottom-part {
  padding: 0.5rem 1rem;
  text-align: center;
  color: #222;
}

.product-decimal {
  font-size: 0.75rem;
  display: inline-block;
}

.product-grid .price-before .product-decimal {
  transform: translate(2px, -3px);
}

.product-grid .price-after .product-decimal {
  transform: translate(2px, -8px);
}

.price-after {
  font-size: 1.5rem;
}

.price-before {
  margin-left: 0.5rem;
  opacity: 0.4;
}

.price-before span {
  text-decoration: line-through;
}

.price {
  margin-bottom: 0;
  font-weight: bold;
}

.discount {
  color: $discountColor;
  margin-bottom: 0;
}

.discount.hide-discount {
  opacity: 0;
  visibility: hidden;
}

.title {
  font-size: 1.125rem;
  margin-top: 1rem;
}


/*




end new */


/*SOPORTE TECNICO*/

.soporte-tecnico-container {
  text-align: center;
  margin-top: 40px;
  max-width: 500px;
}

.soporte-tecnico-container h1 {
  margin: 30px 0;
}

.soporte-tecnico-container form {
  padding: 15px;
  border: 1px solid white;
  border-radius: 5px;
  background-color: white;
  box-shadow: 1px 2px 2px 2px rgba(197, 193, 193, 0.2);
  margin-bottom: 50px;
}

.tecnic-group input,
.tecnic-group textarea {
  width: 100%;
  padding: 10px 0;
  border: 1px solid #0572d160;
  border-radius: 4px;

}

.tecnic-group textarea {
  resize: none;
}

.tecnic-button {
  padding: 10px 30px;
  color: black;
  background-color: #2f9cfcfd;
  border: none;
  font-weight: bold;
  cursor: pointer;
  border-radius: 4px;
}

.tecnic-group label {
  display: block;
  text-align: start;
}

.tecnic-group {
  padding: 10px 0;
}
/* CONTACTO */

.forgot-pass {
  font-size: 0.875rem;
  display: block;
  margin-top: 1rem;
}

.ctc-overlay {
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate3d(100%, 0, 0);
  transition: all 0.5s;
  z-index: 100000000;
}

.ctc-overlay.active {
  transform: translate3d(0, 0, 0);
}

.ctc-overlay form {
  width: 100%;
  max-width: 26rem;
}

.ctc-overlay form img {
  max-width: 15rem;
  width: 100%;
  margin: 0 auto 1.5rem;
  display: block;
}

.ctc-overlay form label {
  display: block;
  font-weight: 600;
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
}

.ctc-overlay form input,
.ctc-overlay form textarea {
  border: 2px solid #0572d1;
  padding: 0.5rem;
  margin-bottom: 1rem;
  width: 100%;
}

.ctc-overlay form textarea {
  resize: vertical;
  height: 5rem;
}

.ctc-overlay form button {
  margin-top: 1.5rem;
}

.ctc-overlay form button i {
  margin-right: 0.5rem;
}

.ctc-overlay .close {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 1.5rem;
}

/* USUARIO NO VERIFICADO COMO MAYORISTA */

.not-verified-yet {
  background: #d4a244;
  color: #fff;
  padding: 0.5rem 0;
}

.not-verified-yet p {
  margin-bottom: 0;
  text-align: center;
}

.not-verified-yet p i {
  margin-right: 0.5rem;
}

/* ENVIOS */

.envio-mercadopago {
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 1rem 0.75rem 0.75rem;
  margin-bottom: 1.5rem;
}

.envio-mercadopago__input_group {
  display: flex;
}

.envio-mercadopago__input_group input {
  transform: scale(1.25);
  margin-left: 2px;
  margin-right: 0.5rem;
}

.envio-mercadopago__input_group label {
  cursor: pointer;
  display: inline-block;
  transform: translateY(-2px);
  color: #444;
  font-weight: 500;
}

.envio-mercadopago__data-entry {
  margin-top: 1rem;
  display: none;
}

.envio-mercadopago__data-entry p i {
  margin-right: 0.5rem;
  color: #666;
}

/* USER ACTIONS */

.checkout-info {
  border: 2px solid $mainColor;
  display: inline-block;
  padding: 0.75rem;
  border-radius: 5px;
  margin-top: 1.5rem;
}

.checkout-info i {
  margin-right: 0.375rem;
  display: inline-block;
  color: $mainColor;
}

.checkout-info p {
  margin-bottom: 0;
}

.user-actions-container {
  color: #222;
 
 
 
}

.user-max-details {
  display: block;
  
}




.user-actions .checkout,
.user-actions .login,
.user-actions .login-dead,
.user-actions .register,
.user-actions .shop

{

  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  color: white;
  font-size: 0.875rem;
  border-radius: 5px;
  padding: 0rem 1rem;
  transition: all 0.22s;
  display: block;
  align-items: center;
}

.user-actions .register {
  border: 1px solid #0572d1;
  color: #0572d1;
  background-color: white;
  border-radius: 0;
  padding: 4px 10px;
  font-weight: 500;
}

/* .user-actions .checkout.active,
.user-actions .checkout:hover,
.user-actions .login:hover {
  background: $mainColor;
  color: #fff;
} */

.user-actions .checkout i,
.user-actions .login i,
.user-actions .login-dead i {
  margin-right: 0.5rem;
}

.user-actions .search-button {
  font-size: 1.25rem;
  margin-left: 2rem;
  cursor: pointer;
  transition: all 0.22s;
}

.user-actions .search-button:hover {
  color: #f1c46f;
}

.user-actions .login-dead-container {
  text-align: center;
  margin-right: 1.5rem;
}

.user-actions .login-dead-container button {
  display: block;
}

.user-actions .login-dead-container .logout {
  font-size: 0.875rem;
  text-decoration: none;
  color: $mainColor;
  font-weight: 600;
  margin: 0 auto;
  background: none;
  border: none;
  cursor: pointer;
}

.user-actions {
  display: flex;
  align-items: center;
}

/* SLIDER */

.slider .slider-slide {
  position: relative;
}

.slider .slider-slide .wrapper {
  position: absolute;
  margin: auto;
  height: 100%;
  display: flex;
  align-items: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.slider .slider-slide {
  color: #fff;
  height: 28rem;
  background-position: center;
  background-size: cover;
}

/*BRANDS*/

.brand img {
  width: 100px;
}

.container-brand{
  padding: 10px 0;
text-align: center;
background: #0572d1;
border-bottom: 1px solid rgba(224, 223, 223, 0.445);
margin-top: 40px;
}
.slider .slider-slide .p1 {
  color: #fff;
  font-size: 2.25rem;
  max-width: 1000px;
  width: 100%;
  text-shadow: 0 2px 4px rgb(0, 0, 0, 0.9);
}

.slider .slider-slide .p2 {
  font-size: 1.5rem;
}

.slider .slider-mask {
  position: absolute !important;
  float: none !important;
  background: url(./assets/images/mask.png);
  opacity: 0.3;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100%;
}

.slider .slider-gallery {
  height: 100%;
}

.slider .slider-static {
  width: 100%;
}

.slider .slider-static img {
  width: 100%;
  display: block;
}

.wp-img{
  width: 20px;
  margin:0 5px;

}

.wp-contain{
display: flex;
  
}

.contacto-telefono {
  display: flex;
  align-items: center;
  margin-top: 12px;
}

.contacto-telefono a {
  margin-left: 6px;
  cursor: pointer;
}

.contacto-telefono i {
  color: white;
}

.contact-foot 
{
  margin-bottom: 5px;
}

.mail-cont{
  color: white;
  margin-bottom: 5px;
}

.footer-wrp {
  padding-top: 30px;
  padding-bottom: 20px;
}

.wp-contain a .wp-contain p {
  font-size: 14px;
  color: white;
}


/* HOME FEATURED*/

.home-featured-2,
.home-featured {
  padding: 1rem 0;
}

.home-featured-2 .cols,
.home-featured .cols {
  display: flex;
  width: 100%;
  justify-content: center;
 
  margin-top: 20px;
  flex-wrap: wrap;
}

.home-featured .cols .col {
  width: calc((70% / 2) - 2rem);
  margin: 1rem;
}

.home-featured-2 .cols .col {
  width: calc((100% / 2) - 2rem);
  margin: 1rem;
  overflow: hidden;
  transition: all 0.22s;
}

.home-featured-2 .cols .col:hover {
  transform: scale(1.1);
}

.home-featured-2 img,
.home-featured img {
  width: 100%;
  transition: all 0.22s;
  display: block;
}

/* MOBILE SLIDER REPLACEMENT */

.slider-mobile {
  width: 100%;
  display: none;
}

.slider-mobile img {
  width: 100%;
}

/* LOGIN FORM */

.main-login form {
  max-width: 24rem;
  width: 100%;
}

.label {
  display: block;
  font-weight: 600;
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
}

.label span {
  color: rgba(#222, 0.6);
}

.input {
  width: 100%;
  padding: 0.625rem;
  margin-bottom: 1rem;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  border: none;
}

textarea.input {
  height: 5rem;
  resize: vertical;
}

.main-login form button {
  margin-top: 0.5rem;
}

.main-login .register-label {
  font-weight: 500;
  margin-top: 2rem;
  text-align: center;
}

.main-login .checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.main-login form .checkbox-container label {
  margin-bottom: 0;
}

.main-login form .checkbox-container input {
  margin-right: 0.5rem;
  transform: scale(1.25);
}

/* ERROR PAGE */

.main-error i {
  color: #0572d1;
  font-size: 2rem;
  margin-right: 1rem;
}

/* SUCCESS PAGE */



.main-success i {
  color: #5cc386;
  font-size: 2rem;
  margin-right: 1rem;
}

/* PAYMENT PENDING PAGE */
.main-pending i {
  color: #f7c983;
  font-size: 2rem;
  margin-right: 1rem;
}

/* VISTA CATEGORIAS */

.main-categories .cols {
  justify-content: center;
  display: flex;
  width: calc(100% + 2rem);
  margin-left: -1rem;
  flex-wrap: wrap;
}

.main-categories .cols .col {
  width: calc((100% / 4) - 2rem);
  margin: 1rem;
  border-radius: 5px;
  background: #fff;
  overflow: hidden;
  display: block;
  text-decoration: none;
  color: inherit;
  transition: all 0.22s;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
}

.main-categories .cols .col:hover {
  transform: translate3d(0, -0.25rem, 0);
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.1);
}

.main-categories .cols .col img {
  width: 100%;
  border-bottom: 1px solid #eee;
}

.main-categories .cols .col p {
  font-size: 1.25rem;
  text-align: center;
  margin-bottom: 0;
  padding: 1rem 0;
}

/* VISTA PRODUCTOS */

.zero-products {
  margin-top: 4rem;
  display: flex;
  align-items: center;
}

.zero-products {
  width: calc(100% - 2rem);
  margin-left: 1rem;
}

.zero-products i {
  color: #d4a244;
  font-size: 1.5rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  display: block;
}

.home-products h2 {
  text-align: center;
  color: #0572d1;
  font-weight: normal;
  font-size: 3.5rem;
  font-weight: bold;
  margin-bottom: 2rem;
}



.home-products .cols,
.main-products .cols {
  display: flex;
  width: calc(100% + 2rem);
  margin-left: -1rem;
  flex-wrap: wrap;
}

.home-products .cols .col,
.main-products .cols .col {
  width: calc((100% / 4) - 2rem);
  margin: 1rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
  background: #fff;
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  transition: all 0.22s;
}

.home-products .cols .col:hover,
.main-products .cols .col:hover {
  transform: translate3d(0, -0.25rem, 0);
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.1);
}

.home-products .bottom-part .price,
.main-products .bottom-part .price {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  justify-content: center;
}

.home-products .bottom-part .price.no-discount,
.main-products .bottom-part .price.no-discount {
  margin-bottom: 0.25rem;
}

.home-products .bottom-part,
.main-products .bottom-part {
  padding: 1rem 2rem;
  text-align: center;
}

.home-products .bottom-part .price-after,
.main-products .bottom-part .price-after {
  font-size: 1.375rem;
  font-weight: bold;
}

.home-products .bottom-part .price-before,
.main-products .bottom-part .price-before {
  font-size: 1.275rem;
  text-decoration: line-through;
  color: #888;
  font-weight: 500;
  margin-left: 0.5rem;
}

.home-products .cols img,
.main-products .cols img {
  width: 100%;
  border-bottom: 1px solid #eee;
}

.home-products .bottom-part .discount,
.main-products .bottom-part .discount {
  background: #0572d1;
  color: #fff;
  display: inline-block;
  padding: 6px 14px;
}

/* VISTA PRODUCTO */

.product-set {
  display: flex;
  margin-top: 2rem;
}

.slider-set {
  width: calc(50% - 1px);
}

.slider-set .product-thumbnail {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border: none;
}

.slider-set .product-slider {
  width: 100%;
}

.slider-set .product-slider .item {
  height: 438px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
}

.slider-set .product-slider .item img {
  max-width: 100%;
  max-height: 100%;
  display: block;
}

.slider-set .product-thumbnail {
  justify-content: center;
  margin-top: 0.5rem;
}

.slider-set .product-thumbnail .thumbnail {
  position: relative;
  overflow: hidden;
}

.slider-set .product-thumbnail .thumbnail img {
  height: 72px;
  padding: 2px;
  display: block;
  transition: all 0.22s;
}

.slider-set .product-thumbnail .thumbnail .bg-fill {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  border: 3px solid #c84e21;
  z-index: 3;
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.slider-set .product-thumbnail .thumbnail .thumbs-left {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
  color: #c84e21;
  z-index: 2;
  display: block;
  width: 100%;
  height: 100%;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.75rem;
  font-weight: bold;
  transition: all 0.22s;
}

.slider-set .product-thumbnail .thumbnail:hover .thumbs-left {
  font-size: 1.5rem;
}

.slider-set .product-thumbnail .thumbnail:hover img {
  transform: scale(1.1);
}

.slider-set .product-thumbnail .thumbnail.active .bg-fill {
  opacity: 1;
}

.product-slider:hover .slick-prev,
.product-slider:hover .slick-next {
  opacity: 1;
}

.slide-wrap .item img {
  cursor: zoom-in;
}

.product-info {
  padding: 2rem;
  width: calc(50% - 1px);
}

.product-code {
  color: rgba(#fff, 0.7);
  font-size: 1.125rem;
  margin-bottom: 1.5rem;
}

.product-info > h2 {
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  color: #222;
}

.product-info .price {
  font-size: 2rem;
  margin-bottom: 0;
}

.product-info .price .product-decimal {
  transform: translate(2px, -13px);
}

.product-info .price.no-discount {
  margin-bottom: 2rem;
}

.product-info .price-after {
  font-size: 2rem;
}

.discount-label {
  margin-bottom: 2rem;
  color: black;
  font-size: 0.875rem;
}

.product-info .price-before {
  font-size: 1.5rem;
  color: rgba(78, 76, 76, 0.5);
  text-decoration: line-through;
}

.product-info .price-before .product-decimal {
  transform: translate(2px, -8px);
}

.purchase-status-item {
  color: #222;
  background-color: rgba(#111, 0.05);
  border-radius: 5px;
  padding: 10px 20px;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  opacity: 1;

  &.previous {
    opacity: 0.7;
  }

  &.current {
    border: 2px solid #68cf68;
    background-color: rgba(#68cf68, 0.2);
  }

  &.next {
    opacity: 0.2;
  }

  p {
    margin-bottom: 0;
    opacity: 0.7;
  }

  div {
    margin-left: 1rem;
  }

  i {
    color: #68cf68;
    font-size: 1.75rem;
  }

  &.next i {
    color: #444;
  }
}

.product-info .discount {
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  display: inline-block;
  color: $discountColor;
 
  margin-bottom: 1.5rem;
}

.hay-stock {
  color: #0572d1;
}

.description-block {
  background: #0572d1;
  margin-top: 2rem;
  min-width: 100%;
  color: #fff;
  padding: 1rem;
  display: inline-block;

  label,
  p {
    color: inherit;
  }
}

.product-info .add-to-cart {
  display: inline-block;
}

.product-info .add-to-cart i {
  margin-right: 0.5rem;
}

.product-info form {
  width: 100%;
  max-width: 20rem;
  margin-bottom: 2rem;
}

.product-info form input:invalid {
  border: 1px solid #0572d1;
  color: #0572d1;
}

.product-info label {
  font-weight: 600;
  display: block;
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
}

.product-info label span {
  color: #888;
}

.product-info select,
.product-info input {
  width: 7rem;
  padding: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  border: 1px solid #ccc;
  
  margin-bottom: 1rem;
}

.product-info .login-label {
  margin-top: 1.5rem;
}

/*OSOTROS*/

.we-text {
  font-weight: bold;
  font-style: italic;
  font-size: 18px;
  color: #0572d1;
}

/* PRODUCT IMAGE LIGHTBOX */

.lightbox {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
  transition: all 0.4s;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lightbox.on {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.lightbox .metadata {
  position: absolute;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 0.5rem;
  color: #fff;
  top: 4rem;
  left: 4rem;
  z-index: 2;
}

.lightbox .metadata .middle-bar {
  margin: 0 0.325rem;
  display: inline-block;
}

.lightbox img {
  max-width: calc(100% - 2rem);
  max-height: calc(100% - 4rem);
  z-index: 2;
  position: relative;
}

.lightbox .close-lightbox,
.lightbox .prev-slide,
.lightbox .next-slide {
  border: none;
  position: absolute;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 0.5rem;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 2;
}

.lightbox .close-lightbox {
  height: 2rem;
  width: 2rem;
  top: 4rem;
  right: 4rem;
}

.lightbox .prev-slide,
.lightbox .next-slide {
  height: 3rem;
  width: 3rem;
  top: 0;
  bottom: 0;
  margin: auto;
}

.lightbox .close-lightbox:hover,
.lightbox .prev-slide:hover,
.lightbox .next-slide:hover {
  background: rgba(0, 0, 0, 0.6);
}

.lightbox .prev-slide {
  left: 4rem;
}

.lightbox .next-slide {
  right: 4rem;
}

.lightbox-backdrop {
  background: transparent;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

/*GENNO*/

/* GENNO */

.genno-cols {
  width: calc(100% + 2rem);
  margin-left: -1rem;
  display: flex;
  flex-wrap: wrap;
}

.genno-col {
  width: calc((100% / 3) - 2rem);
  margin: 1rem;
  background: #f1f1f1;
  padding: 1rem;
  border-radius: 5px;
  border: 1px solid rgb(213, 205, 205);
}

.genno-col h4 {
  font-weight: normal;
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
}

.genno-col .image-slider img {
  width: 100%;
  display: block;
  border-radius: 5px;
}

.genno-col button p {
  font-weight: bold;
}

.genno-col .dlm,
.genno-col button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 300;
  padding: 0.5rem 1rem;
  margin-top: 1.5rem;
  text-align: center;
  background: #fff;
  color: #222;
  border-radius: 5px;
  margin-bottom: 1rem;
  width: 100%;
  border: none;
  font-weight: 400;
}

.geeno-col button {
  max-height: 10px;
}

.genno-col button.open {

max-height: 10000px;
transition: max-height 1s ease;

}

.genno-col button.gcp {
  display: flex;
  justify-content: center;
  font-weight: bold;
  background: #128ff9;
}

.genno-col button.gcp p {
  margin-left: 0.5rem;
  color: #fff;
  font-size: 1.25rem;
}



.genno-col button.tech-data.active {
  border-radius: 5px 5px 0 0;
  margin-bottom: 0;
}

.genno-col button.active .genno-minus {
  display: block;
}

.genno-col button.active .genno-plus {
  display: none;
}

.genno-col .dlm span,
.genno-col button span {
  cursor: pointer;
  padding: 0 6px;
  border-radius: 100%;
}

.genno-col button span.genno-minus {
  padding: 0 8px;
  display: none;
}

.genno-col h3 span:hover {
  background: rgba(255, 255, 255, 0.2);
}

.genno-col ul li {
  margin-left: 1.5rem;
  line-height: 1.5;
  color: #444;
}

.tecnic-data{
  max-height: 0;
  transition: all 1s;
  overflow: hidden;

  &.open {
    max-height: 1100px;
  }
}

.genno-col ul {
  
  border-radius: 0 0 5px 5px;
  padding: 0.5rem;
  color: #000;
  background: #ffffff;
  
}

.genno-col ul.active {
  display: block;
}

.genno-col > a {
  background: linear-gradient(to left top, #06193a, #128ff9);
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  padding: 0.5rem 4rem;
  margin: 1.5rem auto 0;
  width: 100%;
  text-align: center;
  display: block;
}

.genno-fact {
  margin: 0 auto;
  text-align: center;
  width: 100%;
  margin-top: 2rem;
}

.genno-title {
  color: #128ff9;
  font-weight: bold;
  font-size: 30px;
    text-align: start;
}

.featured {
  background: #adffda;
  line-height: 1.5;
  padding: 1rem;
  border-radius: 5px;
  border: 1px solid rgba(0,0,0,.1);
  font-size: 1.25rem;
  font-weight: 200;
  color: #0e3e28;

  p{
    margin-bottom: 0;
  }
}

.gcp {
  p{
    margin-bottom: 0;
  }
}

.genno-col > a.dlm {
  width: 100%;
}

.dlm:hover {
  background: linear-gradient(to left top, #06193a, #128ff9);
  color: #fff;
  cursor: pointer;
}

/* ADD TO CART BUTTON */

/* CHECKOUT SIN VENTA TODAVIA */

.main-sc .shopping-cart-table .row {
  display: flex;
  padding: 0.5rem 1rem;
  align-items: center;
}

.main-sc .shopping-cart-table .row.row-head,
.main-sc .shopping-cart-table .row.row-last {
  border-top: 1px solid rgba(#000, 0.2);
  border-bottom: 1px solid rgba(#000, 0.2);
  padding: 1rem;
}

.main-sc .shopping-cart-table .row-last {
  margin-top: 1rem;
}

.main-sc .shopping-cart-table .row.row-head {
  font-size: 0.875rem;
  font-weight: 600;
  color: #222;
}

.main-sc .shopping-cart-table .row.row-last,
.main-sc .shopping-cart-table .row.row-normal {
  color: #333;
  font-weight: 500;
}

.main-sc .shopping-cart-table .row.row-normal .col-amount span {
  display: inline-block;
  margin: 0 1rem;
}

.main-sc .shopping-cart-table .row.row-normal .col-amount i {
  cursor: pointer;
}

.main-sc .shopping-cart-table .row.row-normal .col-amount i:hover {
  color: #888;
}

.main-sc .shopping-cart-table .row.row-normal .col-amount i.disabled {
  opacity: 0.3;
  pointer-events: none;
}

.main-sc .shopping-cart-table .row.row-last .total {
  font-size: 1.5rem;
}

.main-sc .shopping-cart-table .col:nth-child(1) {
  width: 50%;
}
.main-sc .shopping-cart-table .col:nth-child(2) {
  width: 12%;
  text-align: center;
  display: flex;
}
.main-sc .shopping-cart-table .col:nth-child(3) {
  width: 12%;
  text-align: center;
}
.main-sc .shopping-cart-table .col:nth-child(4) {
  width: 12%;
  text-align: center;
}
.main-sc .shopping-cart-table .col:nth-child(5) {
  width: 12%;
  text-align: center;
}
.main-sc .shopping-cart-table .col:nth-child(6) {
  width: 2%;
  text-align: center;
}

.main-sc .shopping-cart-table .col.col-delete i {
  padding: 0.5rem;
  cursor: pointer;
}

.main-sc .shopping-cart-table .col.col-delete i:hover {
  color: #000;
}

.main-sc .payment-options {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.main-sc .payment-options a {
  background: #fff;
  padding: 1rem 4rem;
  margin: 1rem;
  display: inline-flex;
  align-items: center;
  color: inherit;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.22s;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
  text-align: center;
}

.main-sc .payment-options a:hover {
  transform: translate3d(0, -0.25rem, 0);
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.1);
}

.main-sc .payment-options a span {
  margin-bottom: 0.5rem;
  display: inline-block;
  line-height: 1.5;
}

.main-sc .payment-options a span.no-margin {
  margin: 0;
}

.main-sc .payment-options a img {
  height: 2rem;
  display: block;
  margin: 0 auto;
}

.main-sc .checkout-slider .row-normal .product-decimal {
  transform: translate(2px, -3px);
}

.main-sc .checkout-slider .row-last .product-decimal {
  transform: translate(2px, -8px);
}

.main-sc .checkout-slider {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.main-sc .checkout-slider-track {
  display: flex;
  width: 200%;
}

.main-sc .checkout-slider-right,
.main-sc .checkout-slider-left {
  width: 49%;
  transition: all 0.6s;
}

.main-sc .checkout-slider-left {
  margin-right: 2%;
}

.main-sc .checkout-slider.finish .checkout-slider-left {
  margin-left: -50%;
}

.main-sc .checkout-slider-right h3 {
  color: #222;
}

.main-sc .checkout-slider-right h3 .product-decimal {
  transform: translate(2px, -4px);
}

.main-sc .checkout-slider-right .go-back {
  margin-bottom: 1.5rem;
  display: inline-block;
  cursor: pointer;
}

.main-sc .checkout-slider-right .go-back i {
  margin-right: 0.5rem;
}

.main-sc form {
  max-width: 24rem;
  width: 100%;
  margin: 2rem 0;
}

.main-sc .payment-failed i {
  color: #0572d1;
  margin-right: 1rem;
}

/* QUIENES SOMOS */

.us .cols {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  gap: 4rem;
}

.us .cols img {
  width: 100%;
  border-radius: 5px;
}

.main-about h1 {
  
  text-align: center;
  margin: 0;
}

/* .we-pictures{
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr)  minmax(0, 1fr) ;
  
  margin-left: 30px;
} */

.we-pictures img {
  margin-left: 20px;
 height: 100%;
}



.main-about .head {
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-about .head img {
  border-radius: 100%;
  width: 6rem;
  margin-left: 1.5rem;
}

.main-about img {
  width: 100%;
 
}

.head h1 {
  margin-bottom: 30px;
}

.main-about .cols {
  display: grid;
grid-template-columns: minmax(0, 1fr) minmax(0, 1fr)  ;

}

ut .cols .col {
  width: 100%;
  max-width: 40rem;
  text-align: center;
  margin: 2rem auto 0;
  display: flex;
  align-items: center;
}

.main-about .p-spec {
  font-size: 1.5rem;
  margin-top: 1rem;
}

.main-about .col-1 {
  margin: 80px 0;
  text-align: center;
}

.main-about .nube {
  position: absolute;
  width: 10rem;
  animation: float 4s infinite linear;
  transform: translate3d(0, 2rem, 0);
}

@keyframes float {
  0% {
    transform: translate3d(0, 2rem, 0);
  }

  50% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(0, 2rem, 0);
  }
}

.main-about .nube.n1 {
  top: 14rem;
  left: 10%;
  animation-delay: 0.5s;
}

.main-about .nube.n2 {
  top: 32rem;
  left: 7%;
  animation-delay: 1.5s;
}

.main-about .nube.n3 {
  top: 34rem;
  right: 15%;
  animation-delay: 0s;
}

.main-about .nube.n4 {
  top: 14rem;
  right: 12%;
  animation-delay: 2s;
}

/* CONTACTO */

.main-contacto iframe {
  width: 100%;
  margin: 10px 0;
  
}

.main-contacto img {
  width: 100%;
  border-radius: 5px;
  margin-top: 2rem;
}

.main-contacto .cols .col-1 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.main-contacto .cols .col-2 {
  text-align: center;
}

.main-contacto form label {
  font-weight: 600;
  margin-bottom: 0.25rem;
  display: block;
  font-size: 0.875rem;
}

.main-contacto form input,
.main-contacto form textarea {
  width: 100%;
  display: block;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid #0572d160;
  padding: 0.75rem;
 
}

.form-contact-first input:nth-child(2) {
  margin-right: 10px;
  margin-left: 10px;
}

.button-section-contact{
  text-align: center;
}

.contact-tittle {
  display: flex;
  justify-content: center;
}

.form-contact-first{
  display: flex;

}

.main-contacto form textarea {
  height: 6rem;
  resize: vertical;
 
}

.main-contacto .button {
  margin: 1.5rem 0 ;
  

}

.main-contacto .button i {
  margin-right: 0.5rem;
}

.main-contacto .col-2 h3:not(:first-of-type) {
  margin-top: 1.75rem;
}

.main-contacto .col-2 h3 {
  font-weight: 600;
  margin-bottom: 1rem;
}

.main-contacto .col-2 h3 i {
  color: #0572d1;
  width: 2.5rem;
  text-align: center;
  margin-left: -0.5rem;
}

.main-contacto .col-2 p {
  margin-bottom: 0.5rem;
}

.main-contacto .contact-social {
  margin-left: -1rem;
  margin-top: 2rem;
}

.main-contacto .contact-social a {
  color: #0572d1;
  font-size: 2rem;
  margin: 1rem;
}

.main-contacto .contact-social a:hover {
  color: #222;
}

/*INICIAR SESION*/

.session-button{
  display: flex;
  justify-content: center;
}
.session-init{
  display: flex;
  justify-content: center;
}

.session-tittle{
  display: flex;
  justify-content: center;
  color: #0572d1;
}

/* SHOWROOM INDEX */
.showroom {
  display: block;
  color: inherit;
  text-decoration: none;
  background: #0572d1;
  padding: 24px 0 15px;
  text-align: center;
  margin-bottom: 1rem;
  position: relative;
  overflow: hidden;
}

.showroom .mask {
  background: url(./assets/images/mask.png);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  opacity: 0.4;
}

.showroom:after,
.showroom:before {
  width: 100vw;
  height: 100vw;
  border-radius: 100%;
  background: #f34b8e;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  content: '';
  display: block;
  transform: scale(0);
  opacity: 0;
  animation: ripple 4s infinite;
  animation-delay: 1s;
}

.showroom:after {
  animation-delay: 2.7s;
}

@keyframes ripple {
  0% {
    transform: scale(0);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.showroom .wrapper {
  display: flex;
  position: relative;
  z-index: 40;
  align-items: center;
  justify-content: center;
}

.showroom p {
  color: #fff;
  font-size: 3rem;
}

.showroom a {
  color: #0572d1;
  background: #fff;
  padding: 0.5rem 1.5rem;
  text-decoration: none;
  margin-left: 2rem;
  transform: translateY(-7px);
}

/* WHATSAPP POP */

.whatsapp-opener {
  border: none;
  display: inline-block;
  position: fixed;
  bottom: 2rem;
  left: 2rem;
  z-index: 200;
  background: #455a64;
  padding: 0.625rem 1.25rem;
  cursor: pointer;
  border-radius: 100rem;
  color: #fff;
  font-weight: 500;
  font-size: 0.875rem;
}

.whatsapp-opener i {
  margin-right: 0.5rem;
}

.whatsapp-container {
  width: 100%;
  z-index: 200;
  max-width: 20rem;
  position: fixed;
  bottom: 6rem;
  left: 2rem;
  display: none;
}

.whatsapp-container.active {
  display: block;
}

.whatsapp-container .top {
  color: #fff;
  background: $accentColor;
  position: relative;
  padding: 1rem;
  border-radius: 5px 5px 0 0;
}

.whatsapp-container .top p {
  color: #fff;
  font-size: 0.875rem;
  max-width: 16rem;
  margin-bottom: 0;
}

.whatsapp-container .top i {
  opacity: 0.4;
  position: absolute;
  right: 0.625rem;
  cursor: pointer;
  top: 0.625rem;
}

.whatsapp-container .top i:hover {
  opacity: 0.7;
}

.whatsapp-container .bottom {
  display: flex;
  align-items: center;
  padding: 1rem;
  color: inherit;
  background: #fff;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);
  text-decoration: none;
}

.whatsapp-container .bottom i {
  font-size: 2.25rem;
  color: black;
  margin-right: 1rem;
}

.whatsapp-container .bottom span {
  font-size: 0.875rem;
  opacity: 0.7;
  color: #888;
}

.whatsapp-container .bottom p {
  margin-bottom: 0;
  font-size: 0.9rem;
  font-weight: bold;
}

/*SERVICIOS */

.services-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 40px ;
}

.services-details {
  text-align: center;
}

.services-details img {
  width: 80px;
}

.services-details h1 {
font-size: 16px;
margin-bottom: 10px;
}

.services-details p {
  font-size: 12px;
}

/* FOOTER */

footer {
  flex-shrink: 0;
  background: #0572d1;
  
}

footer .popopen {
  cursor: pointer;
}

footer .cols {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

footer .cols .col {
 
  align-items: center;
}

footer .col-05 img {
  width: 100%;
  max-width: 4rem;
  border-radius: 5px;
  cursor: pointer;
}

footer .col-2 .horarios {
  margin: 0;
  p{
    color: white;
    margin: 0;
    text-align: center;
   
  }
}



footer .col-2 a,
footer .col-3 a {
  display: block;
  border: none;
  background: none;
  font-weight: 500;
  cursor: pointer;
  color: #fff;
  text-decoration: none;
  text-align: start;
  line-height: 1.5;
  margin-right: 0;
}

footer .col-2 a:hover {
  color: #000;
}

footer .col-1 a {
  width: 100%;
  display: inline-block;
}

.details-col-2 {
 cursor: pointer;
    border: none;
    background-color: transparent;
    color: white;
    font-weight: 500;
   
  
}

footer .col-1-5 {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

footer .col-1-5 img {
  cursor: pointer;
  display: inline-block;
  width: 4rem;
  border-radius: 5px;
}

footer .col-1 img {
  width: 100%;
  max-width: 15rem;
  margin: 0 auto;
  display: block;
}

footer .col-4 p {
  margin-bottom: 0;
  color: #fff;
}

footer .col-4 span {
  margin-left: 32px;
}

footer .col-4 p i {
  width: 2rem;
  text-align: center;
  opacity: 0.5;
  display: inline-block;
}

footer .redes {
  display: flex;
  margin-top: 1rem;
}

footer .redes a {
  color: #fff;
  font-size: 2rem;
  margin-right: 0.5rem;
}

.under-footer {
  background: #fff;
  padding: 0.75rem;
}

.under-footer .wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.under-footer p {
  margin-bottom: 0;
  color: #000;
  font-size: 0.75rem;
}

.under-footer a {
  font-size: 0.75rem;

}
.under-footer a img {
  width: 50px;
}

.qr_overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200000000;
  background-color: rgba(0, 0, 0, 0.9);
  align-items: center;
  justify-content: center;
  display: none;
}

.qr_overlay.active {
  display: flex;
}

.qr_overlay > img {
  max-width: calc(100% - 6rem);
  max-height: calc(100% - 6rem);
  display: block;
  border-radius: 5px;
}

.qr_overlay button {
  border: none;
  position: absolute;
  right: 0;
  top: 0;
  margin: 1.5rem;
  color: #fff;
  display: flex;
  width: 3rem;
  height: 3rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: none;
}

.qr_overlay button img {
  width: 70%;
  display: block;
}

.qr_overlay button:hover,
.qr_overlay button:focus {
  opacity: 0.7;
}

.mobile-nav {
  display: none;
}

.pancake-menu {
  
  background: transparent;
  border: none;
  cursor: pointer;
  color: $secondaryColor;
  padding: 4px 10px 4px 10px;
  border-radius: 100rem;
  transition: all 0.22s;
}

.pancake-menu > span {
  font-weight: 600;
}

.pancake-menu .pm-lvl-1 {
  display: flex;
  height: 2.5rem;
  width: 3.2rem;
  align-items: center;
 /*  margin-right: 0.5rem; */
}

.pancake-menu .pm-lvl-2 {
  width: 70%;
  margin: 0 auto;
}

.pancake-menu .pm-lvl-2 span {
  height: 3px;
  margin: 8px 0;
  width: 100%;
  background: #fff;
  display: block;
  transition: all 0.22s;
}

/* header.active .pancake-menu {
  background: #5b253a;
} */

header.active .pancake-menu .pm-lvl-2 span:nth-child(1) {
  transform: rotate(45deg) translate(7px, 8px);
}

header.active .pancake-menu .pm-lvl-2 span:nth-child(2) {
  transform: scaleX(0);
}

header.active .pancake-menu .pm-lvl-2 span:nth-child(3) {
  transform: rotate(-45deg) translate(7px, -8px);
}

/* COMO COMPRAR */

main.htb {
  padding: 0;
}

.htb .main-text {
  font-size: 1.25rem;
  line-height: 1.5;
  padding: 4rem 0 2rem;
  text-align: center;
}

.htb .form-sec {
  background: url(./assets/images/como-comprar/2.jpg) no-repeat center center;
  background-size: cover;
  padding: 12rem 0;
}

.htb .form-sec .wrapper {
  display: flex;
}

.htb .form-sec .col-1 {
  color: #fff;
}

.htb .form-sec .col-1 .p-1 {
  font-size: 3rem;
  padding-right: 2rem;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  margin-bottom: 3.5rem;
}

.htb .form-sec .col-1 .p-2 {
  padding-right: 2rem;
}

.htb .form-sec .col-1 .p-1,
.htb .form-sec .col-1 .p-2 {
  color: #fff;
}

.htb .form-sec .col-1 .p-2 {
  font-size: 1.5rem;
  line-height: 1.4;
}

.htb .form-sec .col-1,
.htb .form-sec .col-2 {
  width: 50%;
}

.htb form {
  background: #fff;
  padding: 2.5rem;
}

.htb form > p.register-label {
  margin-top: 1rem;
}

.htb form input:not([type='radio']),
.htb form textarea {
  border: none;
  border-radius: 0;
  width: 100%;
  border-bottom: 2px solid #ddd;
  padding: 0.75rem;
  margin-bottom: 1rem;
}

.htb form textarea {
  height: 5rem;
  resize: none;
}

.htb form textarea:focus,
.htb form input:not([type='radio']):focus {
  background: #f9f9f9;
  outline: none;
}

.htb select {
  width: 100%;
  border: none;
  border-bottom: 2px solid #ddd;
  padding: 0.75rem;
  margin-bottom: 1rem;
}

.htb button {
  background: #000;
  color: #fff;
  cursor: pointer;
  padding: 0.5rem 2rem;
  border: none;
  border-radius: 5px;
  margin-top: 1rem;
}

.htb .ig {
  display: flex;
  margin: 1rem 0;
}

.htb .ig p {
  margin-right: 1rem;
}

.htb .ig input {
  margin-right: 0.25rem;
}

.htb .ig label {
  margin-right: 1rem;
}

.form-register-after {
  background: #fff;
  padding: 2.5rem;
}

/* POPUP OVERLAY */

.pop-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: bc;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.4s;
  visibility: hidden;
  z-index: 50;
}

.pop-overlay.active {
  opacity: 1;
  visibility: visible;
}

.pop-box * {
  opacity: 0;
  transform: scale(0.8);
  transition: all 0.5s;
  transition-delay: 0.05s;
}

.pop-overlay.active .pop-box * {
  opacity: 1;
  transform: scale(1);
}

.pop-overlay .pop-box {
  background: #fff;
  width: calc(100% - 2.5rem);
  max-width: 32rem;
  padding: 1.5rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}

.pop-overlay i {
  font-size: 1.5rem;
  position: absolute;
  padding: 1.5rem;
  color: #fff;
  right: 0;
  top: 0;
  cursor: pointer;
}

.pop-overlay i:hover {
  opacity: 0.7;
}

.pop-overlay ul li {
  margin-left: 1rem;
}

/* SLIDER */

.home-slide {
  color: #fff;
  height: 28rem;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
}

.home-slide .p1 {
  color: #fff;
  font-size: 2.25rem;
  max-width: 1000px;
  width: 100%;
  text-shadow: 0 2px 4px rgb(0 0 0 / 90%);
}

/* OVERRIDES */

.full-content .alice-carousel__dots {
  margin: -40px 3px 5px;
  position: relative;
  z-index: 2000;
}

.full-content .alice-carousel__dots-item:not(.__custom):hover,
.full-content .alice-carousel__dots-item:not(.__custom).__active {
  background-color: #000;
}

.full-content .alice-carousel__dots-item:not(.__custom) {
  background-color: rgba(0, 0, 0, 0.6);
  width: 11px;
  height: 11px;
}

.full-content .alice-carousel__dots-item:not(.__custom):not(:last-child) {
  margin-right: 10px;
}

.checkout-row-delete-button {
  border: none;
  background: none;
  cursor: pointer;
  color: #222;

  span {
    display: none;
  }

  &:hover {
    color: #000;
  }
}

.cancelled-purchase {
  margin: 1rem 0 2rem;
  p {
    color: #241515;
    font-size: 1.125rem;
    max-width: 600px;

    i {
      color: #943333;
    }

    a {
      color: #943333;
    }
  }
}

/* MEDIA QUERIES */

@media screen and (max-width: 1400px) {
  header nav > ul > li > a {
    padding: 2rem 0.625rem;
  }
}

@media screen and (max-width: 1240px) {
  .main-about .nube {
    display: none;
  }
}

@media screen and (max-width: 1200px) {
  header nav > ul > li > a.active {
    color: #000;
  }

  header nav > ul > li:hover > a {
    color: #0572d1;
  }

  .us .cols {
    display: flex;
    flex-direction: column-reverse;
    gap: 0;
  }

  .us .cols .col-1 {
    margin-top: 1.5rem;
  }

  .hero h1 {
    font-size: 3rem;
  }

  .hero {
    height: 34rem;
  }

  .hero-pouch img {
    margin-right: 5rem;
    height: 25rem;
  }

  header .wrapper > nav {
    position: absolute;
    top: calc(100% + 1rem);
    width: 100%;
    right: 0;
    max-width: 20rem;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    padding: 0.5rem 1rem;
    border-radius: 5px;
    opacity: 0;
    visibility: hidden;
    transform: translate3d(0, -2rem, 0);
    transition: all 0.2s;
    
  }

  header .wrapper > nav.active {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    visibility: visible;
    z-index: 2000;
  }

  header nav > ul > li > ul {
    display: none;
    position: static;
    transform: translate3d(0, 0, 0);
  }

  header nav > ul > li > a {
    padding: 0;
    color: #222;
  }

  header nav .user-actions-container a {
    color: #222;
  }

  header nav > ul > li > a i {
    opacity: 0;
    position: absolute;
    pointer-events: none;
  }

  header nav > ul > li {
    display: block;
    padding: 0.5rem 0;
    text-align: center;
  }

  footer {
    font-size: 0.875rem;
  }

  .home-products .cols,
  .main-product .cols {
    justify-content: center;
  }

  .home-products .cols .col,
  .main-products .cols .col {
    width: calc((100% / 3) - 2rem);
  }

  .pancake-menu {
    display: flex;
    align-items: center;
  }

  header .wrapper {
    position: relative;
    perspective: 1000px;
    z-index: 200;
  }

  .mobile-nav {
    display: block;
    z-index: 15;
    position: absolute;
    width: 100%;
    background: #fff;
    padding: 1.5rem;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    top: calc(100% + 2rem);
    transform-origin: 50% 0%;
    transition: all 0.3s;
    opacity: 0;
    visibility: hidden;
    transform: rotate3d(1, 0, 0, -85deg);
  }

  header.active .mobile-nav {
    opacity: 1;
    visibility: visible;
    transform: rotate3d(0, 0, 0, 0);
  }

  .mobile-nav .mn {
    text-align: center;
  }

  .mobile-nav .mn li {
    list-style: none;
    display: block;
  }

  .mobile-nav .mn > li a {
    text-decoration: none;
    line-height: 1.5;
  }

  .mobile-nav .mn > li.drop {
    margin-top: 1.5rem;
  }

  .mobile-nav .mn > li.drop > a {
    border: 1px solid ;
    display: inline-block;
    padding: 5px 10px;
    border-radius: 4px;
  }

  .mobile-nav .mn > li > ul > li > ul {
    margin-bottom: 1.5rem;
  }

  .mobile-nav .mn > li > ul > li > a {
    color: #222;
  }

  .mobile-user-actions {
    text-align: center;
  }

  .mobile-user-actions .login,
  .mobile-user-actions .login-dead-container,
  .mobile-user-actions .checkout
  .mobi {
    display: block;
    text-decoration: none;
    line-height: 2;
  }

  .mobile-user-actions .login i,
  .mobile-user-actions .login-dead-container i,
  .mobile-user-actions .checkout i {
    opacity: 0.5;
    margin: 0.5rem;
  }

  .mobile-user-actions {
    margin-bottom: 2rem;
  }

  .mobile-user-actions .logout {
    font-size: 0.875rem;
    margin-left: 1rem;
    color: #7590ce;
  }
}

@media screen and (max-width: 1064px) {



.main-about .cols {
  display: block;

}

.we-pictures {
  display: block;
  margin-bottom: 30px;
  margin-left: 0;
 
}




  .product-grid .product[data-index='4'],
  .product-grid .product[data-index='5'] {
    display: none;
  }

  .main-contacto .cols {
    grid-template-columns: minmax(0, 1fr);
  }

  .store-grid,
  .product-grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .product-grid .product[data-index='4'],
  .product-grid .product[data-index='5'] {
    display: block;
  }

  .main-categories .cols .col {
    width: calc((100% / 3) - 2rem);
  }

  .htb .form-sec .col-1,
  .htb .form-sec .col-2 {
    width: 100%;
  }

  .htb .form-sec:after {
    content: '';
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  .htb .form-sec .wrapper {
    display: block;
    position: relative;
    z-index: 3;
  }

  .htb .form-sec .col-1 {
    text-align: center;
    margin-bottom: 2.5rem;
  }

  .htb .form-sec {
    padding: 4rem 0;
    position: relative;
  }
}

@media screen and (max-width: 960px) {


  .services-container {
    display: block;
  }

  .services-details{
    margin: 20px 0;
  }


  .input-search {
    display: none;
  }
  .main-sc .shopping-cart-table .row.row-head {
    display: none;
  }

  .main-sc .shopping-cart-table .row,
  .main-sc .row-last {
    display: block;
  }

  .main-sc .shopping-cart-table .row.row.row-normal {
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
    padding: 1rem 0;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin-bottom: 1rem;
    text-align: center;
  }

  .main-sc .shopping-cart-table .col.col-title {
    font-weight: bold;
    margin-bottom: 1rem;
  }

  .main-sc .shopping-cart-table .col {
    line-height: 1.5;
  }

  .main-sc .shopping-cart-table .col.col-title span {
    position: relative;
  }

  .main-sc .shopping-cart-table .col:nth-child(2).col-amount {
    width: 8rem;
    padding: 8px 0;
    margin: 0 auto;
    border-radius: 4px;
    background-color: $mainColor;
    color: #fff;
    display: block;
  }

  .main-sc .shopping-cart-table .col.col-title span:after {
    height: 1px;
    content: '';
    display: block;
    background-color: rgba(#222, 0.2);
    max-width: 5rem;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -4px;
  }

  .main-sc .shopping-cart-table .col:nth-child(3) {
    margin-top: 1rem;
  }

  .main-sc .shopping-cart-table .col:nth-child(4) {
    margin-bottom: 1rem;
  }

  .main-sc .shopping-cart-table .col:nth-child(3):before,
  .main-sc .shopping-cart-table .col:nth-child(4):before,
  .main-sc .shopping-cart-table .col:nth-child(5):before {
    color: rgba(#222, 0.6);
    margin-right: 0.5rem;
  }

 /*  .main-sc .shopping-cart-table .row-normal .col:nth-child(3):before {
    content: 'Precio unitario:';
  }
 */
 /*   */
  .main-sc .shopping-cart-table .row-last .col:nth-child(4) {
    margin-bottom: -5px;
  }

  .main-sc .shopping-cart-table .col.col-delete button {
    border-radius: 5px;
    padding: 8px 16px;
  }

  .main-sc .shopping-cart-table .col.col-delete button:hover {
    color: #000;
  }

  .main-sc .shopping-cart-table .col.col-delete span {
    display: inline-block;
    font-weight: 600;
    margin-right: 0.5rem;
  }

  .main-sc .shopping-cart-table .col.col-delete i {
    padding: 0;
  }

  .main-sc .row-last .col.col-center {
    text-align: center;
    font-weight: 600;
    color: #666;
    margin-bottom: 0.25rem;
  }

  .main-sc .shopping-cart-table .col:nth-child(1),
  .main-sc .row-last .col:nth-child(1),
  .main-sc .shopping-cart-table .col:nth-child(2),
  .main-sc .row-last .col:nth-child(2),
 
  .main-sc .row-last .col:nth-child(3),
  .main-sc .shopping-cart-table .col:nth-child(4),
  .main-sc .row-last .col:nth-child(4),
  .main-sc .shopping-cart-table .col:nth-child(5),
  .main-sc .row-last .col:nth-child(5) {
    width: 100%;
  }

  .product-decimal{
    display: none;
  }

  .main-sc .shopping-cart-table .col:nth-child(3) {
    display: none;
  }

  

  .hero h1 {
    font-size: 2rem;
  }

  .hero {
    height: 25rem;
  }

  .hero-pouch img {
    height: 18rem;
    margin-right: 0;
  }

  .home-featured .cols .col {
    width: calc((100% / 2) - 2rem);
  }

  .home-featured-2 .cols,
  .home-featured .cols {
    justify-content: center;
  }

  .home-products .cols .col,
  .main-products .cols .col {
    width: calc((100% / 2) - 2rem);
  }

  body {
    overflow-x: hidden;
  }
}

@media screen and (max-width: 860px) {

  .genno-cols {
    width: 100%;
    display: block;
    
}

.genno-col{
  width: 100%;
}

  .brand img {
    width: 80px;
  }

  .form-contact-first {
display: block;

  }
  .form-contact-first input:nth-child(2) {
    margin-right: 0;
    margin-left: 0;
}

.main-contacto form {
  margin: 30px;
}


.background-video{
 display: none;

}

.video-responsive{
  display: block;
}


  footer .col-2 a,
footer .col-3 a {
  display: block;

  text-align: center;

}
  .popup {
  height: 100%;
  width: 100%;
  background-image: url('./assets/images/background-responsive.jpg');
  background-size: cover;
  position: fixed;
  z-index: 4000000;
  background-position: center;
  transform: translate3d(0, -100%, 0);
  transition: all 1s;
}

.popup-visible {
  transform: translate3d(0, 0, 0);
}

  .hero {
    height: auto;
    padding: 5rem 0;
  }

  .hero-wrapper {
    flex-direction: column-reverse;
    text-align: center;
  }

  .slider .slider-slide .p1 {
    font-size: 1.5rem;
  }

  footer .cols {
    display: block;
    text-align: center;
  }

  footer .redes {
    justify-content: center;
  }

  footer .cols .col {
    display: flex;
    text-align: center;
    width: 100%;
    justify-content: center;
  }

  footer .cols .col > div {
    text-align: center;
  }

  .under-footer .wrapper {
    display: block;
    text-align: center;
  }

  .under-footer .wrapper a {
    margin-top: 0.5rem;
    display: inline-block;
  }

  .main-contacto .cols .col-1 {
    width: 100%;
  }

  .main-contacto .cols .col-2 {
    width: 100%;
  }

  .slider-set,
  .product-info {
    width: 100%;
  }

  .product-info form {
    margin: 0 auto 4rem;
  }

  .product-info {
    text-align: center;
  }

  .product-set {
    display: block;
  }

  footer .col-4 span {
    margin-left: 0;
  }
}

@media screen and (max-width: 800px) {
  header .wrapper {
    padding: 0.65rem 0;
  }

  header .logo img {
    height: 3rem;
    margin: 10px 20px;
  }

  .main-sc .payment-options {
    flex-wrap: wrap;
  }

  .main-sc .payment-options a {
    width: 100%;
    text-align: center;
    justify-content: center;
    margin: 0;
    margin-bottom: 1rem;
  }

  .store-grid,
  .product-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .main-categories .cols .col {
    width: calc((100% / 2) - 2rem);
  }

  .htb .top-bar .wrapper {
    display: block;
    text-align: center;
  }

  .htb .top-bar .wrapper > div {
    text-align: center;
    justify-content: center;
  }

  .htb .top-bar .c-1 {
    padding-right: 0;
    border: none;
    margin-bottom: 0.5rem;
  }

  .htb .top-bar .wrapper > div.c-2 {
    display: block;
    margin-left: 0;
  }

  .htb .top-bar .c-2 p {
    width: 100%;
  }

  .htb .top-bar .c-2 a {
    display: inline-block;
    margin-top: 0.5rem;
    margin-left: 0;
  }

  .htb .top-bar .c-1 a:not(.rs) {
    margin-right: 0;
    text-decoration: underline;
  }

  .htb .top-bar .c-1 a.rs {
    display: none;
  }
}

@media screen and (max-width: 760px) {

  .texts-spans {
    font-size: 2rem;
    font-weight: 600;
  }
  

  header nav .checkout.checkout-responsive {
    display: none;
  }
 
  .user-actions .login,
  .user-actions .login-dead-container,
  
  .user-actions .shop,
  .user-actions .register {
    display: none;
  }

  .middle-header input {
    display: none;
  }

  .home-featured-2 .cols .col {
    width: calc((100% / 1) - 2rem);
  }

  header nav .user-actions-container {
    display: block;
    color: #000;
    text-align: center;
    text-transform: uppercase;
   
  }

 header nav .user-actions-container a {
  text-decoration: none;
 }

 header nav .user-actions-container .register {
 margin-top: 10px;
 border-top: 1px solid rgb(225, 222, 222);
 padding-top: 10px;
 }

  header nav .user-actions-container .login,
  header nav .user-actions-container .login-dead {
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 500;
  }

  header nav .user-actions-container .login i,
  header nav .user-actions-container .login-dead i {
    margin-right: 0.5rem;
  }

  header nav .user-actions-container .login-dead-container,
  header nav .user-actions-container .login {
    justify-content: center;
  }

  header nav .login,
  header nav .login-dead-container {
/*     border-top: 1px solid $mainColor;
    border-bottom: 1px solid $mainColor; */
    padding: 12px;
    display: flex;
    align-items: center;
  }

  header nav .login-dead-container button {
    color: #0572d1;
    background: transparent;
    font-weight: 500;
    margin-left: 1rem;
    font-size: 0.875rem;
    cursor: pointer;
    border: none;
  }

  header nav .checkout {
    text-align: center;
    width: 100%;
    display: block;
    padding: 0.75rem 0 0.25rem;
    text-decoration: none;
  }

  header nav .checkout i {
    margin-right: 0.5rem;
  }
}

@media screen and (max-width: 600px) {

  .nice-img {
   display: none;
  }
  
  .nice-img-responsive {
    display: block;
    width: 100%;
  }

  .we-pictures img {
   margin-left: 0;
   margin-top: 10px;
  }
  

  .brand img {
    width: 60px;
  }
  .store-grid,
  .product-grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .top-header p {
    font-size: 0.875rem;
  }

  .home-featured .cols .col {
    width: calc((100% / 1) - 2rem);
  }

  .home-products .cols .col,
  .main-products .cols .col {
    width: calc((100% / 1) - 2rem);
  }

  .hero h1 {
    font-size: 1.5rem;
  }

  .hero-pouch img {
    height: 12rem;
  }
}

@media screen and (max-width: 505px) {

  .certificate-img {
    
  
    img {
      display: none;
      
    }
  }

  .wp-top {
    margin-left: 0;
    font-size: 12px;
  }

  .tecnic-support{
    font-size: 12px;
    padding: 2px 6px;
    margin-right: 0;
  }

  .genno-title {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .genno-fact{
    font-size: 16px;
  }

  .featured {
    p{
      font-size: 14px;
    }
  }

  .user-actions .checkout {
    font-size: 12px;
  }

  header .logo img {
    height: 3rem;
    margin: 8px 8px;
  }
  .main-categories .cols .col {
    width: calc((100% / 1) - 2rem);
  }
}

@media screen and (max-width: 420px) {

  header .wrapper > nav {
   
    left: 0;
   
  
  }
  

  .texts-spans {
    font-size: 1.5rem;
    font-weight: 500;
  }
  

  .brand img {
    width: 50px;
  }
}
